import React from 'react'
import { Redirect, Layout, Hero, Container, Section } from 'components'
import locales from 'config/locales'
import heroImage from 'images/coaching-hero.jpg'
// Photo by Benjamin Elliott on Unsplash

export const CoachingPage = ({ language }) => {
  const locale = locales[language]
  const page = locale.pages.coaching
  return (
    <Layout language={language}>
      <Hero
        backgroundImage={heroImage}
        backgroundPosition={{
          _: '86% 32%',
          sm: '86% -40%',
          md: '86% 20%',
          lg: '86%, 60%',
        }}
        backgroundSize={'113% auto'}
      />
      <Container marginBottom={8} marginTop={2}>
        <Section type="text" {...page.whatIsCoaching} />
        <Section type="text" {...page.howIsASession} />
        <Section type="text" {...page.whatCanCoachingGiveYou} />
        <Section type="list" {...page.togetherWeWillBeAbleTo} />
        <Section type="list" {...page.butAlso} />
        <Section type="list" {...page.myCoachingWillBringYou} />
      </Container>
    </Layout>
  )
}

export default Redirect
